.number-badge {
  border-radius: 2px;
  background-color: #e5eff5;
  color: #2a7ab7;
  padding: 2px 5px;
}

.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: var(--lines-max, 4);
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
  overflow: hidden;
}

.search-match-text-highlight {
  background-color: transparent;
  color: #2a7ab7;
  font-weight: bold;
  text-decoration: underline;
}

.asterisk-str{
  
}