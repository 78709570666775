.selected-icon {
  --size: 16px;
  font-size: var(--size);
}

.selected-icon.tiny {
  --size: 10px;
}

.selected-icon.small {
  --size: 13.3px;
}

.close-icon {
  cursor: pointer;
  color: #aaaaaa;
  transition:
    transform 0.2s ease-in-out,
    color 0.2s ease-in-out;
}

.close-icon:hover {
  color: #2a7ab7;
  transform: scale(1.3);
}

li.groupChildEle {
  padding-left: 5px !important;
  border-top: 1px solid #f5f5f5;
}

.multiSelectContainer input::placeholder {
  color: #aaaaaa;
}

.multiSelectContainer li.groupChildEle {
  color: #757575 !important;
  background-color: transparent;
}

.multiSelectContainer li.groupChildEle:hover {
  color: #1a1a1a !important;
  background-color: transparent;
}

.optionListContainer {
  box-shadow: "0 2px 8px rgba(0, 0, 0, 0.15)";
}

.icon_down_dir:before {
  content: "" !important;
}

.checkbox {
  outline: none;
  content: none;
  cursor: pointer;
}

.checkbox:before {
  font-family: "FontAwesome";
  content: "\f00c";
  font-size: 16px;
  color: transparent !important;
  background: #ffffff;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  margin-right: 10px;
}

.checkbox:checked {
  outline: none;
  content: none;
}

.checkbox:checked:before {
  font-family: "FontAwesome";
  content: "\f00c";
  font-size: 16px;
  color: transparent !important;
  background: #1890ff;
  display: block;
  width: 16px;
  height: 16px;
  border: 1px solid #1890ff;
  border-radius: 2px;
  margin-right: 10px;
}

.word-chunk {
  margin-right: 3px;
  color: #1a1a1a;
  font-weight: 500;
  display: inline-block;
}

.word-chunk:last-child {
  margin-right: 0;
}

.link-text {
  color: #2a7ab7;
  cursor: pointer;
  text-decoration: none;
}

.icon_down_dir {
  display: none;
}
