button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.sticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9;
}

.sticky-lift-0 {
  position: sticky;
  left: 0px;
  top: 0;
  z-index: 100;
}

.sticky-lift-35 {
  position: sticky;
  left: 35px;
  top: 0;
  z-index: 100;
}

.custom-boxshadow {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.MuiTableContainer-root {
  box-shadow: -10px 0px 0px -9px rgba(0, 0, 0, 0.06) !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 22;
}

.link-with-icon {
  position: relative;
  display: inline-block;
}

.icon {
  display: inline-block;
}

.default-icon {
  display: inline-block;
}

.blue-icon {
  display: none;
}

.link-with-icon:hover .default-icon {
  display: none;
}

.link-with-icon:hover .blue-icon {
  display: inline-block;
}
