.activeLink {
  color: #2a7ab7;
  cursor: pointer;
  user-select: none;
}

.activeLink:hover {
  opacity: 0.9;
  text-decoration: underline;
}
