* {
  font-family: "Public Sans" !important;
}

body {
  background-color: #fdfeff;
}

html,
body {
  height: 100%;
  margin: 0;
  background: #fff;
}

#root {
  height: 100%;
  width: 100%;
}
