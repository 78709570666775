.link-with-icon {
    position: relative;
    display: inline-block;
  }
  
  .icon {
    display: inline-block;
  }
  
  .default-icon {
    display: inline-block;
  }
  
  .blue-icon {
    display: none;
  }
  
  .link-with-icon:hover .default-icon {
    display: none;
  }
  
  .link-with-icon:hover .blue-icon {
    display: inline-block;
  }