.pulsEffect::after {
  content: "";
  position: absolute;
  top: 0;
  border-radius: inherit;
  bottom: 0;
  left: 0;
  right: 0;
}

.pulsEffect.animateOnce::after {
  animation: pulse 1s 1;
  background: inherit;
}

.pulsEffect.running::after {
  animation: pulse 1s infinite;
  background: inherit;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
