.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: #0802020f;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #bbc8d1;
}

/* @Animation uitl classes */
.fade-in {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulseRing {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 105, 13, 0.678);
  }
  70% {
    box-shadow: 0 0 0 12px rgba(0, 136, 204, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 136, 204, 0);
  }
}

.pulse-ring {
  position: relative;
  z-index: 1;
}

.pulse-ring::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  transform: translate(-50%, -50%);
  animation: pulseRing 1s ease-out infinite;
  animation-iteration-count: 5;
}


.rotate-infinite {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}